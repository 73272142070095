import React from 'react';
import s from './ArrowsNavigationWrapper.scss';
import {IconButton} from 'wix-ui-tpa';
import {ChevronLeft, ChevronRight} from '@wix/wix-ui-icons-common';
import {withGlobals} from '../../../../globalPropsContext';
import {withTranslations} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ISliderGlobalProps} from '../../../sliderGlobalStrategy';
import {useEnvironment} from '@wix/yoshi-flow-editor';

export type ArrowsNavigationWrapperProps = {
  navigateNext(): void;
  navigatePrev(): void;
  children: React.ReactNode;
} & ISliderGlobalProps;

export enum ArrowsNavigationWrapperDataHook {
  Root = 'ArrowsNavigationWrapperDataHook.Root',
  LeftArrow = 'ArrowsNavigationWrapperDataHook.LeftArrow',
  RightArrow = 'ArrowsNavigationWrapperDataHook.RightArrow',
}

export const ArrowsNavigationWrapper: React.FC<ArrowsNavigationWrapperProps> = withGlobals(
  withTranslations()(({children, globals, navigateNext, navigatePrev}: ArrowsNavigationWrapperProps) => {
    const {sliderGalleryPreviousProduct, sliderGalleryNextProduct} = globals.textsMap;
    const {isRTL} = useEnvironment();

    const leftArrowLabel = isRTL ? sliderGalleryNextProduct : sliderGalleryPreviousProduct;
    const rightArrowLabel = isRTL ? sliderGalleryPreviousProduct : sliderGalleryNextProduct;

    const leftArrowClick = () => (isRTL ? navigateNext() : navigatePrev());
    const rightArrowClick = () => (isRTL ? navigatePrev() : navigateNext());

    return (
      <div className={s.root}>
        <div className={s.arrowContainer}>
          <IconButton
            aria-label={leftArrowLabel}
            data-hook={ArrowsNavigationWrapperDataHook.LeftArrow}
            icon={<ChevronLeft />}
            onClick={leftArrowClick}
          />
        </div>
        <div className={s.sliderWrapper}>{children}</div>
        <div className={s.arrowContainer}>
          <IconButton
            aria-label={rightArrowLabel}
            data-hook={ArrowsNavigationWrapperDataHook.RightArrow}
            icon={<ChevronRight />}
            onClick={rightArrowClick}
          />
        </div>
      </div>
    );
  })
);
