import s from './ProductItemSlide.scss';
import {ProductItemWithGlobals} from '../../../../common/components/ProductItem/ProductItem';
import React from 'react';
import {ISliderProduct} from '../../../../types/sliderGalleryTypes';
import {Announcer} from '@wix/wixstores-client-core/dist/es/src/a11y/announcer';

export interface ProductItemSlideProps {
  product: ISliderProduct;
  index: number;
  a11yAnnouncer: Announcer;
  className?: string;
  dataHook?: string;
}
export const ProductItemSlide: React.FC<ProductItemSlideProps> = ({
  product,
  index,
  a11yAnnouncer,
  className,
  dataHook,
}) => {
  if (product.isFake) {
    return null;
  }

  return (
    <div data-hook={dataHook} className={className}>
      <ProductItemWithGlobals
        className={s.product}
        product={product}
        index={index}
        a11yAnnouncer={a11yAnnouncer}
        announce={(content: string) => a11yAnnouncer.announce(content)}
      />
    </div>
  );
};
